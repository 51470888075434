import {ElMessage} from "element-plus";

const baseUrl = process.env.VUE_APP_API
import store from '@/store'
import {clearStorage} from '@/utils/common'

export const getSSE = (trigger_id, type, message) => {
  if (!localStorage.getItem('token')) {
    return false
  }
  let chatList = store.state.chat.chatList
  let chatKey = store.state.chat.chatKey
  let chatListCurrent = store.state.chat.chatListCurrent
  let sseState = store.state.common.sseState
  const eventSource = new EventSource(`${baseUrl}/drawdata/getdata/${store.state.chat.chatId}/${trigger_id}/${type}?token=${localStorage.getItem('token')}`, {withCredentials: true})
  eventSource.onmessage = event => {
    if (!sseState) {
      return false
    }
    const data = JSON.parse(event.data)
    if(data.type === 'Validation failed'){
      ElMessage.warning('登录过期，请重新登录')
      clearStorage()
      setTimeout(()=>{
        store.commit('common/setLoginMask', true)
      },2000)
      return false
    }
    if (data.type === 'error') {
      store.commit('common/setSseError', true)
      localStorage.setItem('chatValue', message)
      return false
    }
    if (JSON.stringify(chatList) !== '{}') {
      if (data.type !== '' && data.type !== 'close') {
        localStorage.removeItem('chatValue')
        chatList[chatKey][chatListCurrent].list[0].model = 'mj'
        chatList[chatKey][chatListCurrent].list[1].content[0] = {url: data.img}
        chatList[chatKey][chatListCurrent].list[1].type = data.type
        chatList[chatKey][chatListCurrent].list[1].percentage = Number(data.percentage)
        chatList[chatKey][chatListCurrent].list[1].srcFlag = data.img
      }
      if (data.type === 'end' || data.type === 'close') {
        if (data.msgHash) {
          chatList[chatKey][chatListCurrent].list[1].triggerId = data.triggerId
          chatList[chatKey][chatListCurrent].list[1].msgId = data.msgId
          chatList[chatKey][chatListCurrent].list[1].msgHash = data.msgHash
          chatList[chatKey][chatListCurrent].list[1].triggerType = data.triggerType
        }
        store.dispatch('chat/getListData', 0)
        store.commit('chat/setChatValue', '')
        eventSource.close()
        store.commit('common/setLoading', false)
        store.commit('common/setDrawTrue', false)
        store.commit('common/setSseState', false)
      }
    } else {
      eventSource.close()
      store.commit('chat/setChatValue', '')
      store.dispatch('chat/getListData', 0)
      store.commit('common/setLoading', false)
      store.commit('common/setDrawTrue', false)
      store.commit('common/setSseState', false)
    }
  }
  eventSource.onerror = error => {
    console.error('SSE Error:', error)
    eventSource.close()
    store.commit('common/setLoading', false)
    store.commit('common/setDrawTrue', false)
    store.commit('common/setSseState', false)
    store.dispatch('chat/getListData', 0)
  }
}
