<template>
  <div class="upload">
    <el-upload
      class="upload-demo"
      drag
      :action="UploadUrl()"
      :before-upload="beforeUploadFile"
      :on-change="fileChange"
      :on-remove="handleRemove"
      :file-list="fileList"
      :http-request="uploadFile"
      :disabled="fileDisabled"
      v-loading="loadingDialog"
      :on-exceed="exceedFile"
    >
      <img
        style="width: 100%;height: 100%;"
        v-if="fileObj.url"
        :src="fileObj.url"
      />
      <div v-if="!fileObj.url && type === 'main'" class="changetip"><span>*</span>必选</div>
      <div v-if="!fileObj.url && type === 'craft'" class="changetip">可选</div>
      <el-icon v-if="!fileObj.url" class="el-icon--upload"><UploadFilled /></el-icon>
      <div v-if="!fileObj.url" class="el-upload__text">
        将文件拖拽，或<em>点击上传</em>
      </div>
      <div class="el-upload__text_tip" v-if="!fileObj.url">{{tip}}</div>
       <div class="remove" v-if="fileObj.url" @click.stop="handleRemove">
         <img src="../../assets/images/deleteicon.svg" alt="delete">
       </div>
      <!-- <template #tip>
        <div class="el-upload__tip">建议尺寸：1125*1125,可拖拽改变图片顺序，默认首张图为主图，最多可上传9张</div>
      </template> -->
    </el-upload>
  </div>
</template>

<script setup>
import {onMounted,reactive, toRefs, defineEmits, defineProps, computed} from 'vue'
import { ElMessage } from 'element-plus'
import {drawdataUploadimg, drawdataUploadpngimg} from "@/api/expert";
import {useStore} from "vuex";
const store = useStore()
const mainUrl = computed(() => store.state.draw.mainUrl)
onMounted(()=>{
  console.log(1)
  fileList.value = []
  fileObj.value.url = ''
  if(mainUrl.value !== ''){
    fileObj.value.url = mainUrl.value
  }
  emit('getimglist', fileObj.value.url)
})

const props = defineProps({
  tip: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
})

const emit = defineEmits(['getimglist'])
const initData = reactive({
  // 文件
  fileList: [],
  fileObj: {},
  fullscreenLoading: false,
  loadingDialog: false,
  fileDisabled: false
})
const {
  fileList,
  fileObj,
  fullscreenLoading,
  loadingDialog,
  fileDisabled
} = toRefs(initData)
// 文件超出个数限制时的钩子
const exceedFile = (files, fileList) => {
  ElMessage.warning(`只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`);
};



// 文件状态改变时的钩子
const fileChange = file => {
  fileList.value = []
  fileList.value.push(file.raw)
}



const asyncImgChecked = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file) // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image()
      img.src = reader.result
      img.onload = () => {
        if (img.width > 2048 || img.height > 2048) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile = async file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  // let size = file.size / 1024 / 1024;
  if(props.type === 'main'){
    if (extension !== 'png') {
      ElMessage.warning('请上传png格式文件')
      return false
    }
  }
  if(props.type === 'craft'){
    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg' && extension !== 'webp') {
      ElMessage.warning('请上传png、jpg、jpeg、webp的格式文件')
      return false
    }
  }
  let res = await asyncImgChecked(file);
  if (!res) {
    ElMessage.warning("请上传小于2048*2048比例图片");
    return false;
  }
}

const handleRemove = () => {
  fileList.value = []
  fileObj.value.url = ''
  emit('getimglist', fileObj.value.url)
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile = option => {
  // 获取文件的后缀名
  fullscreenLoading.value = true
  const formData = new FormData();
  formData.append('image', option.file);
  if(props.type === 'main'){
    drawdataUploadpngimg(formData).then(({ data: res }) => {
      if (res.data) {
        ElMessage.success(res.msg);
        fileObj.value.url = res.data.url
        emit('getimglist', fileObj.value.url)
        fullscreenLoading.value = false
      }else {
        ElMessage.warning(res.msg);
      }
    })
  }
  if(props.type === 'craft'){
    drawdataUploadimg(formData).then(({ data: res }) => {
      if (res.data) {
        ElMessage.success(res.msg);
        fileObj.value.url = res.data.url
        emit('getimglist', fileObj.value.url)
        fullscreenLoading.value = false
      }else {
        ElMessage.warning(res.msg);
      }
    })
  }
}
</script>
<style lang="scss">
.upload-demo,
.el-upload,
.el-upload-dragger {
  width: 100%;
  height: 100%;
  padding: 0;
}


.el-upload-list {
  display: none;
}

.el-upload-dragger {
  overflow: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2f2f3d;
}

.remove {
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: bold;
  font-size: 22px;
  color: red;
  img {
    width: 20px;
  }
}

.changetip {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 12px;
  color: #fff;
  span {
    color: red;
  }
}

.el-upload__text {
  color: #fff !important;
}

.el-upload__text_tip {
  color: #fff !important;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 10px;
}

.el-upload-dragger .el-icon--upload {
  height: auto;
  line-height: normal;
}

.el-upload__tip {
  margin-top: 0;
  width: 500px;
}
</style>
