<template>
  <div class="draw_l">
    <div class="draw_l_box">
      <div class="box">
        <div class="draw_set">
          <div class="set_t">绘图设置</div>
          <div class="set_s">文本生成图像</div>
          <div class="spellbook">
            <div class="spellbook_name"><img src="../../assets/images/spell.png">&nbsp;&nbsp;咒语书</div>
            <div class="spellbook_more" @click="openSpell">更多咒语书>></div>
          </div>
          <div class="picbox">
            <div
              v-for="item in picList"
              :key="item"
              :class="picActive == item.value ? 'pic_active' : ''"
              class="pic_i"
              @click="handlePic(item.value)"
            >
              <div class="shadow"></div>
              <img :src="item.src"/>
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="set_s">尺寸</div>
          <div class="sizebox">
            <div
              v-for="(item, i) in sizeList"
              v-if="modelCode == 'mj'"
              :key="i"
              :class="sizeActive == item.size ? 'size_active' : ''"
              class="size_i"
              @click="handleSize(item.size)"
            >
              <div v-if="i < sizeList.length - 1" class="size_m"></div>
              <div
                :style="{ fontSize: i == sizeList.length - 1 ? '14px' : '' }"
                class="size_t"
              >
                {{ item.scale }}
              </div>
            </div>
            <div
              v-for="(item, i) in sizeList.filter(item=>item.size!='custom')"
              v-if="modelCode != 'mj'"
              :key="i"
              :class="sizeActive == item.size ? 'size_active' : ''"
              class="size_i"
              @click="handleSize(item.size)"
            >
              <div v-if="i < sizeList.length - 1" class="size_m"></div>
              <div
                :style="{ fontSize: i == sizeList.length - 1 ? '14px' : '' }"
                class="size_t"
              >
                {{ item.scale }}
              </div>
            </div>
            <el-input
              v-if="sizeActive == 'custom'"
              v-model="proportion"
              class="proportion"
              clearable
              placeholder="请输入"
              @blur="handleProportion"
            ></el-input>
            <div v-if="modelCode == 'mj'" class="size_i proportionError">{{ proportionError }}</div>
          </div>
          <template v-if="modelCode != 'mj'">
            <div class="set_s">数量</div>
            <div class="numbox">
              <div class="num_jian" @click="removeNum">
                <img alt="" src="../../assets/images/remove.png"/>
              </div>
              <div class="num">{{ picNum }}</div>
              <div class="num_jia" @click="addNum">
                <img alt="" src="../../assets/images/add.png"/>
              </div>
              <div class="tips">最大4张</div>
            </div>
          </template>
        </div>
        <div class="draw_line"></div>
        <div class="draw_history">
          <div class="history_t">历史记录</div>
          <div class="history_l">
            <div v-for="(item, i) in historyList" :key="i" class="history_i">
              <div v-if="typeof item.content == 'string'" class="t_n">
                <span v-if="!item.style && item.content !=''">{{ item.content }}</span>
                <span v-else>图生图</span>
                <div class="default" v-if="item.style">{{ styleTag(item.style) }}</div>
              </div>
              <div v-else>
                <div class="imgs">
                  <img
                    v-for="(e, index) in item.content"
                    :key="index"
                    :class="
                      e.size == '1024*1024'
                        ? 'answerimg1'
                        : e.size == '1280*720'
                        ? 'answerimg16'
                        : e.size == '720*1280'
                        ? 'answerimg9'
                        : 'answerimgmj'
                    "
                    :src="e.url"
                    @click="handleviewer(item, e.url, i)"
                  />
                </div>
                <div class="info">
                  <div class="time">文本生成图像 {{ item.time }}</div>
                  <div class="copy" @click="copyChange(historyList[i - 1])">
                    <img alt="" src="../../assets/images/copys.png"/>
                    <span>复用创意</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="historyList.length > 0">
            <div
              v-if="Math.ceil(total) / 2 > drawHistoryPage"
              class="more"
              @click="getMoreList"
            >
              点击查看更多···
            </div>
          </template>
          <div v-if="historyList.length == 0" class="more">无历史记录</div>
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="viewerVisible"
      :initial-index="currentIndex"
      :url-list="imageList"
      close-on-press-escape
      hide-on-click-modal
      @close="handleClose"
      @switch="handleSwitch"
    >
    </el-image-viewer>
    <div v-if="viewerVisible" class="downloadImage" @click="downloadImage">
      <el-icon>
        <Download/>
      </el-icon>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {getChatList, historyTypeandusedButton} from '@/api/common'
import {useStore} from 'vuex'

const store = useStore()
const datas = reactive({
  sizeList: [
    {
      scale: '1:1',
      size: '1024*1024'
    },
    {
      scale: '16:9',
      size: '1280*720'
    },
    {
      scale: '9:16',
      size: '720*1280'
    },
    {
      scale: '自定义',
      size: 'custom'
    }
  ],
  picList: [
    {
      title: '自动',
      value: '<auto>',
      src: require('../../assets/images/draw7.jpeg')
    },
    {
      title: '二次元',
      value: '<anime>',
      src: require('../../assets/images/draw8.jpeg')
    },
    {
      title: '3D卡通',
      value: '<3d cartoon>',
      src: require('../../assets/images/draw6.jpeg')
    },
    {
      title: '扁平插画',
      value: '<flat illustration>',
      src: require('../../assets/images/draw5.jpeg')
    },
    {
      title: '素描',
      value: '<sketch>',
      src: require('../../assets/images/draw4.jpeg')
    },
    {
      title: '油画',
      value: '<oil painting>',
      src: require('../../assets/images/draw1.jpeg')
    },
    {
      title: '中国画',
      value: '<chinese painting>',
      src: require('../../assets/images/draw3.jpeg')
    },
    {
      title: '水彩画',
      value: '<watercolor>',
      src: require('../../assets/images/draw2.jpeg')
    }
  ],
  limit: 2,
  historyList: [],
  total: 0,
  proportionError: ''
})
const {sizeList, picList, limit, historyList, total, proportionError} =
  toRefs(datas)
const sizeActive = computed(() => store.state.common.sizeActive)
const chatList = computed(() => store.state.chat.chatList)
const picActive = computed(() => store.state.common.picActive)
const chatId = computed(() => store.state.chat.chatId)
const modelCode = computed(() => store.state.common.modelCode)
const loading = computed(() => store.state.common.loading)
const reloadWeb = computed(() => store.state.chat.reloadWeb)
const modelAndSilde = computed(() => store.state.chat.modelAndSilde)
const historyLogin = computed(() => store.state.common.historyLogin)
const historyLoginTotal = computed(() => store.state.common.historyLoginTotal)

const mjHistoryList = computed({
  get() {
    return store.state.common.mjHistoryList
  },
  set(val) {
    store.commit('common/setMjHistoryList', val)
  }
})

const proportion = computed({
  get() {
    return store.state.common.proportion
  },
  set(val) {
    store.commit('common/setProportion', val)
  }
})
const picNum = computed({
  get() {
    return store.state.common.picNum
  },
  set(val) {
    store.commit('common/setPicNum', val)
  }
})
const drawHistoryPage = computed({
  get() {
    return store.state.common.drawHistoryPage
  },
  set(val) {
    store.commit('common/setDrawHistoryPage', val)
  }
})
const imageList = ref([])
const viewerVisible = ref(false)
const currentIndex = ref(0)
const flag = ref(false)
onMounted(() => {
  if ((modelCode.value === 'sszdraw-v1' || modelCode.value === 'mj') && modelAndSilde.value) {
    drawHistoryPage.value = 1
    if (historyLogin.value.length > 0) {
      historyList.value = historyLogin.value
      total.value = historyLoginTotal.value
    }
  }
})

// 历史记录监听
watch(modelAndSilde, newv => {
  if (newv) {
    drawHistoryPage.value = 1
    if (historyLogin.value) {
      historyList.value = historyLogin.value
      total.value = historyLoginTotal.value
    }
  }
}, {deep: true})

// 历史记录标签
const styleTag = tag => {
  if(tag){
    return picList.value.filter(item => item.value === tag)[0].title
  }
}

// 打开咒语书
function openSpell(){
  store.commit('common/setPromptArrId', [])
  store.commit('common/setSpellIdsIds', [])
  if(!localStorage.getItem('token')){
    store.commit('common/setLoginMask', true)
    return false
  }
  store.commit('common/setSpellBookState',true)
}


// 历史记录分页
const getMoreList = () => {
  if (Math.ceil(total.value) / 2 > drawHistoryPage.value) {
    drawHistoryPage.value++
    moreList()
  }
}
const regex = /^[0-9]+:[0-9]+$/
// 比例输入
const handleProportion = e => {
  if (e.target.value == '') {
    proportionError.value = ''
    return false
  }
  if (regex.test(e.target.value)) {
    proportionError.value = ''
  } else {
    proportionError.value = '格式错误'
  }
}
const gethistorytypeandusedbutton = (item, i) => {
  const data = {
    chatId: chatId.value,
    taskId: item.taskId
  }
  historyTypeandusedButton(data).then((response) => {
    const {data: res} = response
    if (res.code == 0) {
      // res.msg.buttons = res.msg.usedButton.map(item=>item.type)
      res.msg.buttons = res.msg.usedButton.map(item => {
        if (item.type == 'upscale') {
          return `${item.type}_${item.option.index}`
        } else if (item.type == 'zoomout') {
          return `${item.type}_${item.option.zoomout}`
        } else {
          return item.type
        }
      })
      historyList.value[i - 1].button = res.msg
    }
  })
}
// 预览
const handleviewer = async (item, src, i) => {
  const model = historyList.value[i - 1].model
  if (model == 'mj') {
    if (i > 1) {
      await gethistorytypeandusedbutton(item, i)
      if (mjHistoryList.value.length == 0) {
        mjHistoryList.value.unshift(item)
        mjHistoryList.value.unshift(historyList.value[i - 1])
      } else {
        const flag = mjHistoryList.value.every(el => {
          return el.taskId != historyList.value[i].taskId
        })
        if (flag) {
          mjHistoryList.value.unshift(item)
          mjHistoryList.value.unshift(historyList.value[i - 1])
        }
      }
    }
    return false
  }
  imageList.value = []
  const list = []
  historyList.value.forEach(item => {
    if (Object.prototype.toString.call(item.content) == '[object Array]') {
      item.content.forEach(item => {
        const url = item.url.split('_preview')
        imageList.value.push(url[0] + url[1])
        list.push(item.url)
      })
    }
  })
  const index = list.findIndex(item => item == src)
  currentIndex.value = index == -1 ? 0 : index
  viewerVisible.value = true
}

// 切换图片
const handleSwitch = i => {
  currentIndex.value = i
}

// 关闭
const handleClose = () => {
  viewerVisible.value = false
}

// 下载
const downloadImage = () => {
  const url = imageList.value[currentIndex.value]
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = `image.${url.split('talksome')[1].split('.')[1]}`
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  // 移除a标签
  document.body.removeChild(a)
}

// 获取历史记录
const moreList = async () => {
  let darw = [],
    chatids = []
  if (reloadWeb.value) {
    for (const key in chatList.value) {
      // eslint-disable-next-line no-prototype-builtins
      if (chatList.value.hasOwnProperty(key)) {
        const items = chatList.value[key]
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          if (item.type === 'draw') {
            darw.push(item)
          }
          if (item.type === 'chat') {
            chatids.push(item.id)
          }
        }
      }
    }
  }
  if (reloadWeb.value && darw.length == 0) {
    return false
  }
  if (chatids.includes(chatId.value)) {
    return false
  }
  const datas = {
    id: chatId.value,
    limit: limit.value,
    page: drawHistoryPage.value
  }
  await getChatList(datas)
    .then(response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          if (res.msg.firstChatContent) {
            res.msg.firstChatContent.history.forEach((item, i) => {
              if (item.role == 'A') {
                item.content.forEach((el, j) => {
                  el.size = res.msg.firstChatContent.history[i - 1].size
                })
              }
            })
          }
          if (flag.value) {
            historyList.value = res.msg.firstChatContent.history
            flag.value = false
          } else {
            historyList.value = [
              ...historyList.value,
              ...res.msg.firstChatContent.history
            ]
          }
          store.commit('common/setHistoryListArr', historyList.value)
          total.value = res.msg.firstChatContent.totle
        }
      }
    })
    .catch(() => {
    })
}

// 数量加
const addNum = () => {
  if (loading.value) {
    return false
  }
  if (picNum.value < 4) {
    picNum.value++
    // store.commit('common/setPicNum', picNum.value)
  }
}

// 数量减
const removeNum = () => {
  if (loading.value) {
    return false
  }
  if (picNum.value > 1) {
    picNum.value--
    // store.commit('common/setPicNum', picNum.value)
  }
}

// 尺寸
const handleSize = val => {
  if (loading.value) {
    return false
  }
  store.commit('common/setSizeActive', val)
}

// 图片
const handlePic = val => {
  if (loading.value) {
    return false
  }
  store.commit('common/setPicActive', val)
}

// 复用创意
const copyChange = item => {
  store.commit('draw/setGraphMask', false)
  if(item.baseImageUrl) {
    store.commit('draw/setMainUrl', item.baseImageUrl)
    store.commit('draw/setCraftUrl', item.refImageUrl)
    store.commit('chat/setChatValue', item.content)
    setTimeout(()=>{
      store.commit('draw/setGraphMask', true)
    })
    return false
  }
  let size = item.size
  if (item.model == 'mj') {
    if (size != '1:1' && size != '16:9' && size != '9:16') {
      size = 'custom'
      store.commit('common/setProportion', item.size)
    }
  }
  store.commit('common/setMjHistoryList', [])
  store.commit('chat/setModelAndSilde', true)
  store.commit('common/setHistoryLogin', [])
  store.commit('common/setHistoryLoginTotal', [])
  setTimeout(() => {
    store.commit('chat/setModelAndSilde', false)
  }, 200)
  store.commit('common/setSizeActive', size)
  store.commit('common/setPicActive', item.style)
  store.commit('common/setPicNum', item.number)
  store.commit('chat/setChatValue', item.content)
}
</script>
<style lang="scss" scoped>
.downloadImage {
  position: fixed;
  bottom: 30px;
  left: calc(50% + 110px);
  z-index: 9999;
  transform: translateX(-50%);
  height: 44px;
  display: flex;
  align-items: center;

  .el-icon {
    text-align: justify;
    cursor: pointer;
    font-size: 23px;
  }
}

:deep(.el-image-viewer__actions) {
  // width: 320px;
  padding-right: 50px;
}

.draw_l {
  width: $draw-silde;
  position: fixed;
  top: calc(#{$tab-height} + #{$top-height});
  left: $width;
  bottom: 30px;
  background: #3e3f4f;
  overflow: hidden;
  z-index: 200;

  .draw_l_box {
    width: 102%;
    height: 100%;
    overflow-y: auto;

    .draw_set {
      padding: 20px 16px 24px 24px;
      max-height: 590px;
      box-sizing: border-box;

      .set_t {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 23px;
        margin-bottom: 14px;
      }

      .set_s {
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        margin-bottom: 14px;
      }

      .spellbook {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .spellbook_name {
          font-size: 14px;
          display: flex;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
            vertical-align: middle;
          }
        }

        .spellbook_more {
          font-size: 14px;
          color: #ff7a04;
          cursor: pointer;
        }
      }

      .picbox {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4px;

        .pic_i {
          width: 120px;
          height: 90px;
          position: relative;
          border-radius: 4px;
          margin-bottom: 10px;
          cursor: pointer;
          margin-right: 10px;

          &:nth-child(3n) {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .shadow {
            background: linear-gradient(
                180deg,
                rgba(40, 40, 51, 0.15) 0%,
                rgba(40, 40, 51, 0.9) 100%
            );
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 10;
          }

          span {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 4px;
            z-index: 11;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 16px;
          }
        }

        .pic_active {
          border: 2px solid #f87c05;

          .shadow {
            background: transparent;
          }
        }
      }

      .sizebox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .size_i {
          display: flex;
          align-items: center;
          width: 117px;
          height: 30px;
          background: #333341;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;
          margin-bottom: 10px;

          .size_m {
            border-radius: 2px;
            border: 1px solid #b7baed;
            margin-right: 20px;
            box-sizing: border-box;
          }

          .size_t {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 19px;
          }

          &:nth-child(1) {
            .size_m {
              width: 14px;
              height: 14px;
            }
          }

          &:nth-child(2) {
            .size_m {
              width: 20px;
              height: 11.25px;
            }
          }

          &:nth-child(3) {
            .size_m {
              height: 20px;
              width: 11.25px;
            }
          }
        }

        .size_active {
          border: 1px solid #ff7900;
        }

        .proportionError {
          font-size: 14px;
          background: transparent;
          cursor: default;
          color: rgb(250, 51, 51);
          justify-content: flex-start;
        }
      }

      :deep(.proportion) {
        width: 117px;
        height: 30px;
        margin-bottom: 10px;

        .el-input__wrapper {
          background: transparent;
          border-color: #ff7a04 !important;
          box-shadow: 0 0 0 1px #ff7a04 inset !important;
        }

        .el-input__inner {
          color: #fff;
        }
      }

      .numbox {
        display: flex;
        align-items: center;

        .num_jia,
        .num_jian {
          width: 40px;
          height: 30px;
          background: #333341;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .num {
          width: 72px;
          height: 30px;
          background: #333341;
          border-radius: 3px;
          margin: 0 4px;
          text-align: center;
          line-height: 30px;
        }

        .tips {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          margin-left: 14px;
        }
      }
    }

    .draw_line {
      background: #333341;
      height: 20px;
    }

    .draw_history {
      background: #3e3f4f;
      padding: 23px;
      box-sizing: border-box;
      position: relative;

      .more {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #f87c05;
        cursor: pointer;
        user-select: none;
        margin: 20px 0;
      }

      .default {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 60px;
        height: 26px;
        background: rgba(255, 226, 201, 0.3);
        border-radius: 3px;
        // border: 1px solid #3e3f4f;
        text-align: center;
        line-height: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #fa7905;
      }

      .history_t {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .history_l {
        .history_i {
          padding: 16px 13px 16px 20px;
          background: #333341;
          border-radius: 4px;
          position: relative;

          &:nth-child(even) {
            margin-top: -15px;
            padding-top: 0;
            margin-bottom: 10px;
          }

          .t_n {
            span {
              display: inline-block;
              width: 280px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 16px;
            }
          }

          .imgs {
            margin-top: 10px;

            img {
              margin: 10px;
              margin-left: 0;
              margin-top: 0;
              background: #ccc;
              cursor: pointer;
            }

            .answerimg1 {
              width: 70px;
              height: 70px;
            }

            .answerimg16 {
              width: 64px;
              height: 36px;
            }

            .answerimg9 {
              width: 36px;
              height: 64px;
            }

            .answerimgmj {
              width: 70px;
            }
          }

          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
              font-size: 14px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.5);
            }

            .copy {
              display: flex;
              align-items: center;
              cursor: pointer;

              img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }

              span {
                font-size: 14px;
                font-weight: 400;
                color: #f87c05;
              }
            }
          }
        }
      }
    }
  }
}
</style>
