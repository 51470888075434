<!-- eslint-disable no-undef -->
<!-- 聊天对话 -->
<template>
  <div class="draw">
    <!-- 点击历史记录展示 -->
    <div class="list_item" v-if="mjHistoryList.length>0">
      <template v-for="(chat, index) in mjHistoryList"  :key="index">
        <div v-if="Object.prototype.toString.call(chat.content) == '[object String]'" class="user">
          <img class="avatar" src="../../assets/images/chat.svg"/>
          <span v-if="mjHistoryList[index-1]&&mjHistoryList[index-1].style&&chat.content === ''">图生图</span>
          <span v-else>{{ chat.content }}</span>
        </div>
        <div v-if="Object.prototype.toString.call(chat.content) == '[object Array]'" class="content">
          <div class="imglist" v-if="mjHistoryList[index-1].model == 'mj'">
            <img
              v-for="(item, index) in chat.content"
              :key="index"
              :src="item.url.replace('preview','middle')"
              class="answerimg"
              @click="handleviewerMjHistory(index,item.url)"
            />
          </div>
          <div class="imglist" v-if="mjHistoryList[index-1].model.indexOf('sszdraw-v1') != -1">
            <img
              v-for="(item, index) in chat.content"
              :key="index"
              :class="
                  mjHistoryList[index-1]&&mjHistoryList[index-1].style?(sizeActiveLoad == '1024*1024'
                    ? 'answerimg1'
                    : sizeActiveLoad == '1280*720'
                    ? 'answerimg16'
                    : 'answerimg9'):''
                "
              :src="item.url"
              class="answerimg"
              @click="handleviewerMjHistory(index,item.url)"
            />
          </div>
        </div>
        <div v-if="Object.prototype.toString.call(chat.content) == '[object Array]'">
          <!-- 全部uv刷新 -->
          <template v-if="mjHistoryList[index-1].button">
            <div
              v-if="
              [
                'imagine',
                'reset',
                'variation',
                'solo_high_variation',
                'solo_low_variation',
                'zoomout'
              ].includes(mjHistoryList[index-1].type)
            "
              class="mjtab"
            >
              <div
                v-for="(item, i) in uvList"
                :key="i"
                :class="mjHistoryList[index-1].button.buttons&&mjHistoryList[index-1].button.buttons.includes(`${item.type}_${item.value}`)?'active':''"
                class="mjtab_item"
                @click="uvClick(item,index)"
              >
                {{ item.label }}
              </div>
              <div class="mjtab_item refresh" @click="uvClickRefresh(index)">
                <img src="../../assets/images/refresh.png"/>
              </div>
            </div>
            <!-- u和刷新 -->
            <div v-if="expand.includes(mjHistoryList[index-1].type)" class="mjtab">
              <div
                v-for="(item, i) in uList"
                :key="i"
                :class="mjHistoryList[index-1].button.buttons.includes(`${item.type}_${item.value}`)?'active':''"
                class="mjtab_item"
                @click="uvClick(item,index)"
              >
                {{ item.label }}
              </div>
              <div class="mjtab_item refresh" @click="uvClickRefresh(index)">
                <img src="../../assets/images/refresh.png"/>
              </div>
            </div>
          </template>
          <div v-if="mjHistoryList[index-1].button">
            <!-- 变化 -->
            <div
              v-if="mjHistoryList[index-1].type =='upscale' && !expand.includes(mjHistoryList[index-1].button.typehistoryType.firstType)"
              class="operatebox">
              <div
                v-for="(item, i) in variation"
                :key="i"
                :class="mjHistoryList[index-1].button.buttons.includes(item.type)?'active':''"
                class="operateitem"
                @click="handleoperate(item,index)"
              >
                <img :src="item.img"/>
                {{ item.label }}
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <!-- 清晰度 -->
              <div
                v-if="['upscale', 'upsample_v5_2x', 'upsample_v5_4x'].includes(mjHistoryList[index-1].type) && !expand.includes(mjHistoryList[index-1].button.typehistoryType.firstType)"
                class="operatebox"
              >
                <div
                  v-for="(item, i) in upsample"
                  :key="i"
                  :class="mjHistoryList[index-1].button.buttons.includes(item.type)?'active':''"
                  class="operateitem"
                  @click="handleoperate(item,index)"
                >
                  <img :src="item.img"/>
                  {{ item.label }}
                </div>
              </div>
              <!-- 上下 -->
              <div
                v-if="mjHistoryList[index-1].type != ''&&(mjHistoryList[index-1].type =='upscale' ||((mjHistoryList[index-1].button.typehistoryType.firstType == 'up' ||mjHistoryList[index-1].button.typehistoryType.firstType == 'down')&&!['up','down'].includes(mjHistoryList[index-1].type)))"
                class="operatebox"
              >
                <template
                  v-if="mjHistoryList[index-1].button.typehistoryType.firstType != 'right' && mjHistoryList[index-1].button.typehistoryType.firstType != 'left'">
                  <div
                    v-for="(item, i) in downup"
                    :key="i"
                    :class="mjHistoryList[index-1].button.buttons.includes(item.type)?'active':''"
                    class="operateitem"
                    @click="handleoperate(item,index)"
                  >
                    <img
                      :src="item.img"
                      :style="`transform: rotate(${
                        item.deg ? item.deg : 0
                      }deg)`"
                    />
                    {{ item.label }}
                  </div>
                </template>
              </div>
              <!-- 左右 -->
              <div
                v-if="mjHistoryList[index-1].type =='upscale' ||((mjHistoryList[index-1].button.typehistoryType.firstType == 'left' ||mjHistoryList[index-1].button.typehistoryType.firstType == 'right')&&!['left','right'].includes(mjHistoryList[index-1].type))"
                class="operatebox"
              >
                <template
                  v-if="mjHistoryList[index-1].button.typehistoryType.firstType != 'up' && mjHistoryList[index-1].button.typehistoryType.firstType != 'down'">
                  <div
                    v-for="(item, i) in leftright"
                    :key="i"
                    :class="mjHistoryList[index-1].button.buttons.includes(item.type)?'active':''"
                    class="operateitem"
                    @click="handleoperate(item,index)"
                  >
                    <img
                      :src="item.img"
                      :style="`transform: rotate(${
                        item.deg ? item.deg : 0
                      }deg)`"
                    />
                    {{ item.label }}
                  </div>
                </template>
              </div>
            </div>
            <!-- 扩展 -->
            <div style="display: flex; width: 100%">
              <div
                v-if="mjHistoryList[index-1].type =='upscale'"
                class="operatebox"
              >
                <div
                  v-for="(item, i) in zoomout"
                  :key="i"
                  :class="mjHistoryList[index-1].button.buttons.includes(`${item.type}_${item.value}`)?'active':''"
                  class="operateitem"
                  @click="handleoperate(item,index)"
                >
                  <img :src="item.img"/>
                  {{ item.label }}
                </div>
              </div>
              <div
                v-if="expand.includes(mjHistoryList[index-1].button.typehistoryType.firstType)&&!expand.includes(mjHistoryList[index-1].type)"
                class="operatebox"
              >
                <div
                  v-for="(item, i) in zoomout1"
                  :key="i"
                  :class="mjHistoryList[index-1].button.buttons.includes(`${item.type}_${item.value}`)?'active':''"
                  class="operateitem"
                  @click="handleoperate(item,index)"
                >
                  <img :src="item.img"/>
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- 有记录聊天对话区域 -->
    <template
      v-if="
        chatList[chatKey] &&
        chatList[chatKey][chatListCurrent] &&
        chatList[chatKey][chatListCurrent].list &&
        chatList[chatKey][chatListCurrent].list.length > 0 &&
        chatList[chatKey][chatListCurrent].type == 'draw'
      "
    >
      <div class="list_item">
        <!-- 正式对话内容 -->
        <div class="user">
          <img class="avatar" src="../../assets/images/chat.svg"/>
          <span v-if="!chatList[chatKey][chatListCurrent].list[0].style && chatList[chatKey][chatListCurrent].list[0].content === ''">图生图</span>
          <span v-else>{{ chatList[chatKey][chatListCurrent].list[0].content }}</span>
        </div>
        <div class="content">
          <template v-if="currentModel == 'sszdraw-v1'">
            <div v-loading="loadingimg" :style="imgliststyle" class="imglist">
              <template v-if="!loading">
                <img
                  v-for="(item, index) in chatList[chatKey][chatListCurrent]
                    .list[1].content"
                  :key="index"
                  :class="
                    chatList[chatKey][chatListCurrent]
                    .list[0].style?(sizeActiveLoad == '1024*1024'
                      ? 'answerimg1'
                      : sizeActiveLoad == '1280*720'
                      ? 'answerimg16'
                      : 'answerimg9'):''
                  "
                  :src="item.url"
                  class="answerimg"
                  @click="handleviewer(index)"
                  @load="imageLoaded(index)"
                />
              </template>
              <template v-if="loading">
                <img
                  v-for="(item, index) in picNum"
                  :key="index"
                  :class="chatList[chatKey][chatListCurrent]
                    .list[0].style?(sizeActive == '1024*1024'
                      ? 'answerimg1'
                      : sizeActive == '1280*720'
                      ? 'answerimg16'
                      : 'answerimg9'):'answerimg1'
                  "
                  :src="
                    chatList[chatKey][chatListCurrent]
                    .list[0].style?(sizeActive == '1024*1024'
                      ? require('../../assets/images/placeholder1.png')
                      : sizeActive == '1280*720'
                      ? require('../../assets/images/placeholder16.png')
                      : require('../../assets/images/placeholder9.png')):require('../../assets/images/placeholder1.png')
                  "
                  class="answerimg"
                />
              </template>
            </div>
            <div
              v-if="loadingimg && showProgressbar"
              :class="[!showProgressbar ? 'fade-out-show' : '']"
              :style="progressbartyle"
              class="fade-out progressbar"
            >
              <div :style="{ width: progress + '%' }" class="progress"></div>
              <span :style="{ left: progress + '%' }" class="progresst">
                {{ progress }}%
              </span>
            </div>
          </template>
          <template v-if="currentModel == 'mj'">
            <div v-if="!sseError" class="imglist mjimglist">
              <template
                v-if="chatList[chatKey][chatListCurrent].list[1].percentage > 0 || chatList[chatKey][chatListCurrent].list[1].srcFlag != ''">
                <img
                  v-for="(item, index) in chatList[chatKey][chatListCurrent].list[1].content"
                  id="mjimg"
                  :key="index"
                  :class="currentModel == 'sszdraw-v1'? (sizeActiveLoad == '1024*1024'? 'answerimg1': sizeActiveLoad == '1280*720'? 'answerimg16': 'answerimg9'):''"
                  :src="item.url"
                  class="answerimg"
                  @click="handleviewerMj(index)"
                />
              </template>
              <template v-if="loading&&!chatList[chatKey][chatListCurrent].list[1].srcFlag">
                <div v-loading="loading&&!chatList[chatKey][chatListCurrent].list[1].srcFlag" :class="
                    sizeActive == '1024*1024'
                      ? 'answerimg1'
                      : sizeActive == '1280*720'
                      ? 'answerimg16'
                      : sizeActive == '720*1280'
                      ? 'answerimg9'
                      : 'answerimg1'
                  "
                     style="margin-bottom: 16px">
                  <img :class="
                    sizeActive == '1024*1024'
                      ? 'answerimg1'
                      : sizeActive == '1280*720'
                      ? 'answerimg16'
                      : sizeActive == '720*1280'
                      ? 'answerimg9'
                      : 'answerimg1'
                  "
                       :src="
                    sizeActive == '1024*1024'
                      ? require('../../assets/images/placeholder1.png')
                      : sizeActive == '1280*720'
                      ? require('../../assets/images/placeholder16.png')
                      : sizeActive == '720*1280'
                      ? require('../../assets/images/placeholder9.png')
                      : require('../../assets/images/placeholder1.png')
                  "
                       class="answerimg"
                  />
                </div>
              </template>
            </div>
            <div v-if="sseError" class="sseerror">
              <p>很抱歉，图片生成失败，请再尝试一次。一般是服务拥挤造成，重试可解决。</p>
              <div class="imgbox" @click="looperror">
                <img class="loop" src="../../assets/images/refresh.png">
              </div>
            </div>
            <div
              v-if="chatList[chatKey][chatListCurrent].list[1].type == 'start' || chatList[chatKey][chatListCurrent].list[1].type == 'generating'||loading"
              :class="[!chatList[chatKey][chatListCurrent].list[1].type == 'start' && chatList[chatKey][chatListCurrent].list[1].type == 'generating' ? 'fade-out-show' : '']"
              :style="{width:sizeActive == '1024*1024'
                      ? '320px'
                      : sizeActive == '1280*720'
                      ? '556px'
                      : sizeActive == '720*1280'
                      ? '270px'
                      : '320px'}"
              class="fade-out progressbar progressbarmj"
            >
              <div :style="{width:chatList[chatKey][chatListCurrent].list[1].percentage + '%'}" class="progress"></div>
              <span :style="{left:chatList[chatKey][chatListCurrent].list[1].percentage + '%'}" class="progresst">
                {{ chatList[chatKey][chatListCurrent].list[1].percentage || 0 }}%
              </span>
            </div>
            <div
              v-if="chatList[chatKey][chatListCurrent].list[1].content.length > 0 && chatList[chatKey][chatListCurrent].list[1].content[0].url && chatList[chatKey][chatListCurrent].list[0].button">
              <!-- 全部uv刷新 -->
              <div
                v-if="
                [
                  'imagine',
                  'reset',
                  'variation',
                  'solo_high_variation',
                  'solo_low_variation',
                  'zoomout'
                ].includes(chatList[chatKey][chatListCurrent].list[0].type)
              "
                class="mjtab"
              >
                <div
                  v-for="(item, i) in uvList"
                  :key="i"
                  class="mjtab_item"
                  @click="uvClick(item)"
                >
                  {{ item.label }}
                </div>
                <div class="mjtab_item refresh" @click="uvClickRefresh">
                  <img src="../../assets/images/refresh.png"/>
                </div>
              </div>
              <!-- u和刷新 -->
              <div v-if="expand.includes(chatList[chatKey][chatListCurrent].list[0].type)" class="mjtab">
                <div
                  v-for="(item, i) in uList"
                  :key="i"
                  class="mjtab_item"
                  @click="uvClick(item)"
                >
                  {{ item.label }}
                </div>
                <div class="mjtab_item refresh" @click="uvClickRefresh">
                  <img src="../../assets/images/refresh.png"/>
                </div>
              </div>
              <div v-if="chatList[chatKey][chatListCurrent].list[0].type != 'imagine'">
                <!-- 变化 -->
                <div
                  v-if=" chatList[chatKey][chatListCurrent].list[0].type == 'upscale' && !expand.includes(chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType)"
                  class="operatebox">
                  <div
                    v-for="(item, i) in variation"
                    :key="i"
                    class="operateitem"
                    @click="handleoperate(item)"
                  >
                    <img :src="item.img"/>
                    {{ item.label }}
                  </div>
                </div>
                <div style="display: flex; width: 100%">
                  <!-- 清晰度 -->
                  <div
                    v-if="['upscale', 'upsample_v5_2x', 'upsample_v5_4x'].includes(chatList[chatKey][chatListCurrent].list[0].type) && !expand.includes(chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType)"
                    class="operatebox">
                    <div
                      v-for="(item, i) in upsample"
                      :key="i"
                      class="operateitem"
                      @click="handleoperate(item)"
                    >
                      <img :src="item.img"/>
                      {{ item.label }}
                    </div>
                  </div>
                  <!-- 上下 -->
                  <div
                    v-if="chatList[chatKey][chatListCurrent].list[0].type != ''&&(chatList[chatKey][chatListCurrent].list[0].type =='upscale' ||(chatList[chatKey][chatListCurrent].list[0].button && (chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType == 'up' ||chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType == 'down')&&!['up','down'].includes(chatList[chatKey][chatListCurrent].list[0].type)))"
                    class="operatebox">
                    <template
                      v-if="chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType != 'right' && chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType != 'left'">
                      <div
                        v-for="(item, i) in downup"
                        :key="i"
                        class="operateitem"
                        @click="handleoperate(item)"
                      >
                        <img
                          :src="item.img"
                          :style="`transform: rotate(${
                          item.deg ? item.deg : 0
                        }deg)`"
                        />
                        {{ item.label }}
                      </div>
                    </template>
                  </div>
                  <!-- 左右 -->
                  <div
                    v-if="chatList[chatKey][chatListCurrent].list[0].type =='upscale' ||((chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType == 'left' ||chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType == 'right')&&!['left','right'].includes(chatList[chatKey][chatListCurrent].list[0].type))"
                    class="operatebox"
                  >
                    <template
                      v-if="chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType != 'up' && chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType != 'down'">
                      <div
                        v-for="(item, i) in leftright"
                        :key="i"
                        class="operateitem"
                        @click="handleoperate(item)"
                      >
                        <img
                          :src="item.img"
                          :style="`transform: rotate(${
                          item.deg ? item.deg : 0
                        }deg)`"
                        />
                        {{ item.label }}
                      </div>
                    </template>
                  </div>
                </div>
                <!-- 扩展 -->
                <div style="display: flex; width: 100%">
                  <div
                    v-if="chatList[chatKey][chatListCurrent].list[0].type =='upscale'"
                    class="operatebox"
                  >
                    <div
                      v-for="(item, i) in zoomout"
                      :key="i"
                      class="operateitem"
                      @click="handleoperate(item)"
                    >
                      <img :src="item.img"/>
                      {{ item.label }}
                    </div>
                  </div>
                  <div
                    v-if="expand.includes(chatList[chatKey][chatListCurrent].list[0].button.typehistoryType.firstType)&&!expand.includes(chatList[chatKey][chatListCurrent].list[0].type)"
                    class="operatebox"
                  >
                    <div
                      v-for="(item, i) in zoomout1"
                      :key="i"
                      class="operateitem"
                      @click="handleoperate(item)"
                    >
                      <img :src="item.img"/>
                      {{ item.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="clearfix"></div>
    </template>
    <!-- 无记录聊天对话区域 -->
    <template v-else>
      <div class="list_item">
        <div v-if="drawTitle != '' && flagDrawTitle" class="user">
          <img class="avatar" src="../../assets/images/chat.svg"/>
          {{ drawTitle }}
        </div>
        <div class="content">
          <div v-loading="loadingimg" class="imglist">
            <template v-if="loading">
              <img
                v-for="(item, index) in picNum"
                :key="index"
                :class="
                  sizeActive == '1024*1024'
                    ? 'answerimg1'
                    : sizeActive == '1280*720'
                    ? 'answerimg16'
                    : 'answerimg9'
                "
                :src="
                  sizeActive == '1024*1024'
                    ? require('../../assets/images/placeholder1.png')
                    : sizeActive == '1280*720'
                    ? require('../../assets/images/placeholder16.png')
                    : require('../../assets/images/placeholder9.png')
                "
                class="answerimg"
              />
            </template>
          </div>
          <div
            v-if="showProgressbar"
            :class="[!showProgressbar ? 'fade-out-show' : '']"
            :style="progressbartyle"
            class="fade-out progressbar"
          >
            <div :style="{ width: progress + '%' }" class="progress"></div>
            <span :style="{ left: progress + '%' }" class="progresst">
              {{ progress }}%
            </span>
          </div>
        </div>
      </div>
    </template>
    <el-image-viewer
      v-if="viewerVisible"
      :initial-index="currentIndex"
      :url-list="imageList"
      close-on-press-escape
      hide-on-click-modal
      @close="handleClose"
    >
    </el-image-viewer>
    <div v-if="viewerVisible" class="downloadImage" @click="downloadImage">
      <el-icon>
        <Download/>
      </el-icon>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {historyTypeandusedButton} from "@/api/common";

const store = useStore()

const chatList = computed(() => store.state.chat.chatList)
const chatKey = computed(() => store.state.chat.chatKey)
const chatListCurrent = computed(() => store.state.chat.chatListCurrent)
const drawTrue = computed(() => store.state.common.drawTrue)
const sizeActiveLoad = computed(() => store.state.common.sizeActiveLoad)
const sizeActive = computed(() => store.state.common.sizeActive)
const chatValue = computed(() => store.state.chat.chatValue)
const picNum = computed(() => store.state.common.picNum)
const chatChange = computed(() => store.state.common.chatChange)
const currentModel = computed(() => store.state.common.currentModel)
const modelCode = computed(() => store.state.common.modelCode)
const loading = computed(() => store.state.common.loading)
const mjHistoryList = computed(() => store.state.common.mjHistoryList)
const historyListArr = computed(() => store.state.common.historyListArr)
const sseError = computed(() => store.state.common.sseError)
const chatId = computed(() => store.state.chat.chatId)
const mainUrl = computed(() => store.state.draw.mainUrl)

const loadingimg = ref(false)
const progress = ref(0)
const imageList = ref([])
const viewerVisible = ref(false)
const currentIndex = ref(0)
const showProgressbar = ref(false)
const drawTitle = ref('')
const imgliststyle = ref('')
const progressbartyle = ref('')
const picNums = ref(0)
const emit = defineEmits(['uvClickpost'])
const type = ref('')
const expand = ref(['up', 'left', 'down', 'right'])

watch(
  historyListArr,
  (nval) => {
    if (nval.length > 2) {
      type.value = nval[2].type
    }
  },
  {deep: true}
)


const uvList = ref([
  {
    label: 'U1',
    value: 1,
    type: 'upscale'
  },
  {
    label: 'U2',
    value: 2,
    type: 'upscale'
  },
  {
    label: 'U3',
    value: 3,
    type: 'upscale'
  },
  {
    label: 'U4',
    value: 4,
    type: 'upscale'
  },
  {
    label: 'V1',
    value: 1,
    type: 'variation'
  },
  {
    label: 'V2',
    value: 2,
    type: 'variation'
  },
  {
    label: 'V3',
    value: 3,
    type: 'variation'
  },
  {
    label: 'V4',
    value: 4,
    type: 'variation'
  }
])

const uList = ref([
  {
    label: 'U1',
    value: 1,
    type: 'upscale'
  },
  {
    label: 'U2',
    value: 2,
    type: 'upscale'
  },
  {
    label: 'U3',
    value: 3,
    type: 'upscale'
  },
  {
    label: 'U4',
    value: 4,
    type: 'upscale'
  }
])

const variation = [
  {
    label: '变化（大）',
    img: require('../../assets/images/bh.png'),
    type: 'solo_high_variation',
    value: 1
  },
  {
    label: '变化（小）',
    img: require('../../assets/images/bh.png'),
    type: 'solo_low_variation',
    value: 1
  }
]

const upsample = [
  {
    label: '提升清晰度（2x）',
    img: require('../../assets/images/fd.png'),
    type: 'upsample_v5_2x',
    value: 1
  },
  {
    label: '提升清晰度（4x）',
    img: require('../../assets/images/fd.png'),
    type: 'upsample_v5_4x',
    value: 1
  }
]

const downup = [
  {
    label: '上扩',
    img: require('../../assets/images/jt.png'),
    type: 'up',
    deg: '0'
  },
  {
    label: '下扩',
    img: require('../../assets/images/jt.png'),
    type: 'down',
    deg: 180
  }
]

const leftright = [
  {
    label: '左扩',
    img: require('../../assets/images/jt.png'),
    type: 'left',
    deg: -90
  },
  {
    label: '右扩',
    img: require('../../assets/images/jt.png'),
    type: 'right',
    deg: 90
  }
]

const zoomout = [
  {
    label: '扩展画面（2x）',
    img: require('../../assets/images/fdj.png'),
    type: 'zoomout',
    value: 50
  },
  {
    label: '扩展画面（1.5x）',
    img: require('../../assets/images/fdj.png'),
    type: 'zoomout',
    value: 75
  }
]


const zoomout1 = [
  {
    label: 'Make Square',
    img: require('../../assets/images/fdj.png'),
    type: 'zoomout',
    value: 100
  }
]

watch(picNum, val => {
  picNums.value = val
})

const flagDrawTitle = computed(() => {
  return (
    chatList.value[chatKey.value] &&
    chatList.value[chatKey.value][chatListCurrent.value] &&
    chatList.value[chatKey.value][chatListCurrent.value].list &&
    chatList.value[chatKey.value][chatListCurrent.value].type == 'draw'
  )
})

let timer = null
// 绘图点击开始聊天
watch(drawTrue, newv => {
  if (newv == 'error') {
    chatList.value[chatKey.value][chatListCurrent.value].list = []
    drawTitle.value = ''
    progress.value = 0
    loadingimg.value = false
    showProgressbar.value = false
    clearInterval(timer)
    return false
  }
  if (newv == true) {
    // 根据请求时间更新进度条
    drawTitle.value = chatValue.value
    progress.value = 0
    loadingimg.value = true
    showProgressbar.value = true
    countWH()
    updateProgress()
  }
})

// 会话列表切换监听
watch(chatChange, newv => {
  if (newv) {
    countWH()
  }
})

// error重新发送
const looperror = () => {
  store.commit('common/setSseError', false)
  let option = {}, type = '',
    taskId = 0
  if (
    chatList.value[chatKey.value][chatListCurrent.value] &&
    chatList.value[chatKey.value][chatListCurrent.value].list[1]
  ) {
    const {msgHash, msgId, triggerId} =
      chatList.value[chatKey.value][chatListCurrent.value].list[1]
    if (chatList.value[chatKey.value][chatListCurrent.value].list[0].type == 'imagine') {
      option = {
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      }
    } else {
      option = chatList.value[chatKey.value][chatListCurrent.value].list[0].option
      taskId = chatList.value[chatKey.value][chatListCurrent.value].list[0].parentTaskId
    }
    type = chatList.value[chatKey.value][chatListCurrent.value].list[0].type
    emit('uvClickpost', JSON.stringify(option), type, taskId, 'error')
  }
}

// u1 v1点击
const uvClick = async (item, historyIndex) => {
  let option = {}, taskId = 0
  if(modelCode.value != 'mj'){
    return false
  }
  if (historyIndex || historyIndex === 0) {
    const {msgHash, msgId, triggerId} = mjHistoryList.value[historyIndex]
    option = {
      index: item.value,
      msg_id: msgId,
      msg_hash: msgHash,
      trigger_id: triggerId
    }
    taskId = mjHistoryList.value[historyIndex].taskId
    store.commit('chat/setChatValue', mjHistoryList.value[historyIndex - 1].content)
  } else {
    if (
      chatList.value[chatKey.value][chatListCurrent.value] &&
      chatList.value[chatKey.value][chatListCurrent.value].list[1]
    ) {
      const {msgHash, msgId, triggerId} =
        chatList.value[chatKey.value][chatListCurrent.value].list[1]
      option = {
        index: item.value,
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      }
      taskId = chatList.value[chatKey.value][chatListCurrent.value].list[0].taskId
      store.commit('chat/setChatValue', chatList.value[chatKey.value][chatListCurrent.value].list[0].content)
    }
  }
  await pushlist()
  emit('uvClickpost', JSON.stringify(option), item.type, taskId)
}

const uvClickRefresh = async (historyIndex) => {
  let option = {}, type = '', taskId = 0
  if(modelCode.value != 'mj'){
    return false
  }
  if (typeof historyIndex == "number" && (historyIndex || historyIndex === 0)) {
    const {msgHash, msgId, triggerId} = mjHistoryList.value[historyIndex]
    if (mjHistoryList.value[historyIndex - 1].type == 'imagine') {
      option = {
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      }
      taskId = 0
    } else {
      option = mjHistoryList.value[historyIndex - 1].option
      option.msg_id = msgId
      option.msg_hash = msgHash
      option.trigger_id = triggerId
      taskId = mjHistoryList.value[historyIndex].taskId
    }
    type = mjHistoryList.value[historyIndex - 1].type
    store.commit('chat/setChatValue', mjHistoryList.value[historyIndex - 1].content)
  } else {
    if (
      chatList.value[chatKey.value][chatListCurrent.value] &&
      chatList.value[chatKey.value][chatListCurrent.value].list[1]
    ) {
      const {msgHash, msgId, triggerId} =
        chatList.value[chatKey.value][chatListCurrent.value].list[1]
      if (chatList.value[chatKey.value][chatListCurrent.value].list[0].type == 'imagine') {
        option = {
          msg_id: msgId,
          msg_hash: msgHash,
          trigger_id: triggerId
        }
      } else {
        option = chatList.value[chatKey.value][chatListCurrent.value].list[0].option
        option.msg_id = msgId
        option.msg_hash = msgHash
        option.trigger_id = triggerId
      }
      type = chatList.value[chatKey.value][chatListCurrent.value].list[0].type
      taskId = chatList.value[chatKey.value][chatListCurrent.value].list[0].taskId
      store.commit('chat/setChatValue', chatList.value[chatKey.value][chatListCurrent.value].list[0].content)
    }
  }
  emit('uvClickpost', JSON.stringify(option), type, taskId)
}

// 点击其他按钮操作
const handleoperate = async (item, historyIndex) => {
  let option = {}, taskId = 0
  if(modelCode.value != 'mj'){
    return false
  }
  if (historyIndex || historyIndex === 0) {
    const {msgHash, msgId, triggerId} = mjHistoryList.value[historyIndex]
    if (item.type == 'zoomout') {
      option = JSON.stringify({
        zoomout: item.value,
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      })
    }
    if (item.value == 1) {
      option = JSON.stringify({
        index: 1,
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      })
    }
    if (item.deg) {
      option = JSON.stringify({
        direction: item.type,
        msg_id: msgId,
        msg_hash: msgHash,
        trigger_id: triggerId
      })
    }

    store.commit('chat/setChatValue', mjHistoryList.value[historyIndex - 1].content)
    taskId = mjHistoryList.value[historyIndex].taskId
  } else {
    if (
      chatList.value[chatKey.value][chatListCurrent.value] &&
      chatList.value[chatKey.value][chatListCurrent.value].list[1]
    ) {
      const list = chatList.value[chatKey.value][chatListCurrent.value].list
      const {msgHash, msgId, triggerId} = list[1]
      if (item.type == 'zoomout') {
        option = JSON.stringify({
          zoomout: item.value,
          msg_id: msgId,
          msg_hash: msgHash,
          trigger_id: triggerId
        })
      }
      if (item.value == 1) {
        option = JSON.stringify({
          index: 1,
          msg_id: msgId,
          msg_hash: msgHash,
          trigger_id: triggerId
        })
      }
      if (item.deg) {
        option = JSON.stringify({
          direction: item.type,
          msg_id: msgId,
          msg_hash: msgHash,
          trigger_id: triggerId
        })
      }
      taskId = chatList.value[chatKey.value][chatListCurrent.value].list[0].taskId
      store.commit('chat/setChatValue', chatList.value[chatKey.value][chatListCurrent.value].list[0].content)
    }
  }
  await pushlist()
  emit('uvClickpost', option, item.type, taskId)
}

const pushlist = async () => {
  if (chatList.value[chatKey.value][chatListCurrent.value] && chatList.value[chatKey.value][chatListCurrent.value].list[1]) {
    const list = chatList.value[chatKey.value][chatListCurrent.value].list
    await gethistorytypeandusedbuttonlist(list)
    if (mjHistoryList.value.length == 0) {
      mjHistoryList.value.push(list[0])
      mjHistoryList.value.push(list[1])
    } else {
      const flag = mjHistoryList.value.every(el => {
        return el.taskId != list[0].taskId
      })
      if (flag) {
        mjHistoryList.value.push(list[0])
        mjHistoryList.value.push(list[1])
      }
    }
  }
}

// 当前聊天对话按钮
const gethistorytypeandusedbuttonlist = (list) => {
  const data = {
    chatId: chatId.value,
    taskId: list[0].taskId
  }
  historyTypeandusedButton(data).then((response) => {
    const {data: res} = response
    if (res.code == 0) {
      res.msg.buttons = res.msg.usedButton.map(item => {
        if (item.type == 'upscale') {
          return `${item.type}_${item.option.index}`
        } else {
          return item.type
        }
      })
      list[0].button = res.msg
    }
  })
}

// 计算宽高
const countWH = () => {
  if(mainUrl.value !== ''){
    progressbartyle.value = `width:${270 * picNum.value + 16 * picNum.value - 16}px;`
  }
  if (sizeActive.value == '1024*1024') {
    if (picNum.value > 2) {
      imgliststyle.value = 'width:657px;height:657px;'
      progressbartyle.value = 'width:657px;'
    } else if (picNum.value == 1) {
      imgliststyle.value = 'width:320px;height:320px;'
      progressbartyle.value = 'width:320px;'
    } else if (picNum.value == 2) {
      imgliststyle.value = 'width:657px;height:320px;'
      progressbartyle.value = 'width:657px;'
    }
  }
  if (sizeActive.value == '1280*720') {
    if (picNum.value > 2) {
      imgliststyle.value = 'width:1129px;height:645.5px;'
      progressbartyle.value = 'width:1129px;'
    } else if (picNum.value == 1) {
      imgliststyle.value = 'width:556px;height:313px;'
      progressbartyle.value = 'width:556px;'
    } else if (picNum.value == 2) {
      imgliststyle.value = 'width:1129px;height:320px;'
      progressbartyle.value = 'width:1129px;'
    }
  }
  if (sizeActive.value == '720*1280') {
    imgliststyle.value = `width:${
      270 * picNum.value + 16 * picNum.value
    }px;height:480px;`
    progressbartyle.value = `width:${270 * picNum.value + 16 * picNum.value - 16}px;`
  }
}

// 更新进度条
const updateProgress = () => {
  loadingimg.value = true
  timer = setInterval(() => {
    progress.value = Number(
      Number(progress.value + Math.random() * 10 + 1).toFixed(2)
    )
    if (progress.value >= 99) {
      progress.value = 99
      clearInterval(timer)
    }
  }, 1000)
}

// 图片加载
const imageLoaded = index => {
  countWH()
  if (JSON.stringify(chatList.value) == '{}') {
    return false
  }
  if (
    chatList.value[chatKey.value] &&
    chatList.value[chatKey.value][chatListCurrent.value] &&
    chatList.value[chatKey.value][chatListCurrent.value].list &&
    chatList.value[chatKey.value][chatListCurrent.value].list.length > 0 &&
    chatList.value[chatKey.value][chatListCurrent.value].type == 'draw'
  ) {
    if (chatList.value[chatKey.value][chatListCurrent.value].type == 'chat') {
      progress.value = 0
      return false
    }
    chatList.value[chatKey.value][chatListCurrent.value].list[1].content[
      index
      ].loaded = true
    const allImagesLoaded = chatList.value[chatKey.value][
      chatListCurrent.value
      ].list[1].content.every(image => image.loaded)
    if (allImagesLoaded) {
      loadingimg.value = false
    }
    const totalImages =
      chatList.value[chatKey.value][chatListCurrent.value].list[1].content
        .length
    const loadedImages = chatList.value[chatKey.value][
      chatListCurrent.value
      ].list[1].content.filter(image => image.loaded).length
    progress.value = Math.floor((loadedImages / totalImages) * 100)
    if (progress.value == 100) {
      showProgressbar.value = false
    }
  }
}

// 预览
const handleviewer = index => {
  imageList.value = chatList.value[chatKey.value][
    chatListCurrent.value
    ].list[1].content.map(item => item.url)
  currentIndex.value = index
  viewerVisible.value = true
}

// mj预览
const handleviewerMj = index => {
  imageList.value = chatList.value[chatKey.value][
    chatListCurrent.value
    ].list[1].content.map(item => item.url.replace('_middle', ''))
  currentIndex.value = index
  viewerVisible.value = true
}
// 历史记录预览图片
const handleviewerMjHistory = (index, url) => {
  imageList.value = [url.replace('_preview', '')]
  currentIndex.value = index
  viewerVisible.value = true
}

// 关闭
const handleClose = () => {
  viewerVisible.value = false
}

// 下载
const downloadImage = () => {
  const url =
    chatList.value[chatKey.value][chatListCurrent.value].list[1].content[
      currentIndex.value
      ].url
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = `image.${url.split('talksome')[1].split('.')[1]}`
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  // 移除a标签
  document.body.removeChild(a)
}
</script>
<style lang="scss" scoped>
.downloadImage {
  position: fixed;
  bottom: 30px;
  left: calc(50% + 110px);
  z-index: 9999;
  transform: translateX(-50%);
  height: 44px;
  display: flex;
  align-items: center;

  .el-icon {
    text-align: justify;
    cursor: pointer;
    font-size: 23px;
  }
}

:deep(.el-image-viewer__actions) {
  padding-right: 50px;
}

.draw {
  margin-left: $draw-silde;
  height: 100%;
}

.list_item {
  width: 100%;
  //height: 100%;
  padding: 10px 72px;
  box-sizing: border-box;

  .user {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: -30px;
    color: #ffffff;

    .avatar {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }

  .content {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    padding-top: 10px;

    .imglist {
      font-size: 0;
      line-height: normal;
      //min-width: 320px;
      //min-height: 312.75px;
      //margin-bottom: 16px;
    }

    .answerimg {
      margin-right: 16px;
      margin-bottom: 16px;
      cursor: pointer;
      // object-fit: contain;
    }

    .answerimg1 {
      width: 320px;
      height: 320px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .answerimg16 {
      width: 556px;
      height: 312.75px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .answerimg9 {
      width: 270px;
      height: 480px;

      &:nth-child(4) {
        margin-right: 0;
      }
    }
  }
}

.progressbar {
  width: calc(100% - 130px);
  height: 8px;
  background: #282833;
  border-radius: 4px;
  position: relative;
  margin-top: 16px;

  .progress {
    height: 100%;
    background: #f87c05;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
  }

  .progresst {
    font-size: 12px;
    font-weight: 400;
    color: #939399;
    position: absolute;
    top: 7px;
    transform: translateX(-13px);
  }
}

.progressbarmj {
  width: 550px;
}

.progressbar1 {
  width: 680px;
}

.progressbar16 {
  width: 1150px;
}

.progressbar9 {
  width: 1164px;
}

.other_item {
  .user {
    align-items: flex-start;

    .avatar {
      margin-top: 2px;
    }
  }
}

.self {
  // background: #fff;
  border: none;
}

.loading {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 10%;
  z-index: 11;
  background: transparent;
}

.cloading {
  background: transparent;
  color: #888 !important;
}

.blinkCursor {
  color: #888 !important;
  font-weight: bold;
  animation: Blink 1s ease-in 0s infinite;
}

@keyframes Blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  transition: opacity 1s; /* 定义渐变动画的持续时间为1秒 */
  opacity: 1; /* 初始不透明度为1（完全可见） */
}

.fade-out-show {
  opacity: 0; /* 终点不透明度为0（完全透明） */
}

:deep(.content) {
  .el-loading-mask {
    background: rgba(51, 51, 65, 0.5);
  }
}

.mjtab {
  font-size: 0;
  width: 350px;
  position: relative;
  //margin-top: 10px;

  .mjtab_item {
    display: inline-block;
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #4E5058;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      background: #5865F2;
    }

    &.active {
      background: #5865F2;
    }
  }

  .refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -75px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.operatebox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .operateitem {
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4E5058;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    box-sizing: border-box;
    // &:nth-child(1),
    // &:nth-child(2) {
    //   width: 155px;
    // }
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background: #5865F2;
    }

    &.active {
      background: #5865F2;
    }
  }
}

.sseerror {
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    color: #ff7900;
    line-height: 30px;
    text-align: center;
  }

  .imgbox {
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #a6abd2;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loop {
    width: 20px;
    height: 20px;
  }
}
</style>
